import styled from "styled-components";

const HomeComponent = styled.div`
.fade-in {
    transition: opacity 2s ease;
}
.fade-out {
    opacity: 0;
    transition: opacity 2s ease;
}


 .home_first_section { 
    /* padding: 50px 0; */
    padding-top: 170px;
    text-align: center;
    @media (max-width: 1280px){
    
    }
    @media (max-width: 767px){
        padding-top: 150px;
    }
    .nutrtion-section {
         h3 {
            font-size: 45px;
            line-height: 49px;
            color: #5fb0b9;
            font-weight: bold;
            font-family: "Integral CF";
            text-align: center;
            max-width: 720px;
            margin: 0 auto;
            letter-spacing: 0.5px;
            padding: 20px 0;
        }

    }
    .content-section {
        max-width: 1170px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        z-index: 1;
        h3 {
            font-size: 38px;
            line-height: 49px;
            color: var(--green); 
            font-family: var(--inte-BD);
            margin-bottom: 25px;
            
            @media (max-width: 1280px){
                font-size: 36px;
            @media (max-width: 767px){
                font-size: 22px;
                line-height: normal;
        } 
        } 
        span{ 
            font-family: var(--inte-BD);
            display: block;
            width: 100%;
        }
        }
        .crack{
        /* negi */
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center; 
            font-size: 38px;
            margin-bottom: 25px;
            line-height: 38px;
            color: #ffffff;
            font-family: var(--common-font);
            text-align: center;
            strong {
                font-family: var(--inte-BD);   
                text-transform: uppercase;
                color: #FFC900;
                border-bottom: 3px solid #fff !important;
                line-height: 48px;

            } 
            @media (max-width: 1280px){
                font-size: 36px; 
            } 
            @media (max-width: 767px){
            font-size: 22px;
            line-height: normal;
            flex-wrap: wrap;
            }
        }
        p.finger {
            margin: 40px 0 20px;
            @media (max-width: 1280px){
                margin: 30px 0;
            }
            @media (max-width: 767px){
                margin: 10px 0;
            } 
        }
        p{
            font-size: 27px;
            color: #ffffff;
            font-family: var(--common-font);
            text-align: center;
            margin-bottom: 0;
            &:last-child {
                margin-top: 24px;
                font-size: 26px;
                @media (max-width: 767px){
                    font-size: 22px;
                }
            }
            @media (max-width: 1199px){
                font-size: 24px;
            }
            @media (max-width: 767px){
                font-size: 22px;
                line-height: normal;
            } 
        }
    }
    .nutrtion-section{
        background-image: linear-gradient(90deg,#e40685 0%,#bd1b86 21%,#632e8a 100%);
        padding: 20px 0;
        text-align: center;
        color: #ffffff;
        margin-bottom: 40px;
        width: 100%;
        position: absolute;
        z-index: 99;
        h2{
            font-size:45px;
            @media(max-width:767px){
                font-size:20px;
            }
        }
    }
    .video_sec { 
        box-shadow: 0 0 12px rgb(0 0 0 / 50%);  
        max-width: 900px;
        /* margin-top: 200px; */
        height: 506px;
        margin: 200px auto 0;
        position: relative;
        z-index: 1;
        overflow: hidden;
        @media (max-width: 991px){
        height: 430px;
        max-width: max-content;
        }
        @media (max-width: 767px){
            height: auto;
            max-width: calc(100% - 40px);
        }
            iframe{ 
            position: absolute;
            top: 50%;
            transform: translate(-50% , -50%);
            left: 50%;
            width: 100vw;
            height: 100%;   
            @media (max-width: 767px){
                width: 100%;
                height: 100%;
            }
        }
        & > img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; 
                -webkit-animation: seconds 3.0s forwards;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-delay: 2s;
                animation: seconds 1.0s forwards;
                animation-iteration-count: 1;
                animation-delay: 2s;
                position: relative;
            }
            @-webkit-keyframes seconds {
            0% {
                opacity: 1;
                visibility:visible;
            }
            100% {
                opacity: 0;
                visibility:hidden;
            }
            }
            @keyframes seconds {
                0% {
                opacity: 1;
                visibility:visible;
            }
            100% {
                opacity: 0;
                visibility:hidden;
            }
        }
    }
    .content-section-main {
        padding: 120px 0 50px;
        background-image: linear-gradient(90deg, #e40685 0%, #bd1b86 21%, #632e8a 100%);
        margin-top: -100px;
    }  
 }

 .home_second_section {
    /* padding-top: 60px; */
    .white_strip {
        display: flex;
        background: #fff;
        column-gap: 10px;
        justify-content: center;
        padding: 18px 0;
        font-weight: 600;
        font-size: 19px;
        @media (max-width:568px){
        width: 100%;
    display: block !important;
    text-align: center;
        }
        span{
            font-size:19px;
            @media (max-width:991px){
            font-size:15px;

        }
        @media (max-width:568px){
            font-size:12px;

        }
        }
       
        svg {
            color: green;
        }
    }
}
    /* section 2nd css */
    .first_section.flex_class,.second_section {
        /* max-width: 1470px; */
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        z-index: 1; 
        &>p {
        font-size: 20px;
        color: #939393;
        font-family: var(--common-font);
        text-align: center;
        margin: 25px 0;
        }
        .image_section {
            max-width:1080px;
            margin: 0 auto;
            img {
            width:100%;
            }
        }
        h2 {
            text-align: center;
            font-size: 32px;
            line-height: 49px; 
            margin-bottom: 30px;
            margin-top: 0;
            @media (max-width: 1280px){
                font-size: 28px;
                @media (max-width: 767px){
                    font-size: 20px;
                    line-height: normal;
                    margin-bottom: 20px;
                }
            }
        }
        .circle_section {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 767px){
            flex-wrap: wrap;
            }
            & > div {
            width: calc(100%/3 - 40px);
            height: 100%;
            border-radius: 50%;
            /* background-image: linear-gradient(223deg,#20a4dd 0%,#1ea3b6 24%,#1fa2a9 38%,#25a07b 100%); */
            background-image:linear-gradient(90deg, #e40685 0%, #bd1b86 21%, #632e8a 100%);
            aspect-ratio: 1/1;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @media (max-width: 767px){
                width: 100%;
                max-width: 230px;
                margin: 0 auto;
            }   
                p {
                font-size: 135px;
                color: #fff;
                font-weight: bold;
                font-family: var(--common-font);
                text-align: center; 
                line-height: initial;
                @media (max-width: 1440px){
                    font-size: 100px;
                }
                @media (max-width: 1280px){
                    font-size: 80px;
                } 
                @media (max-width: 1199.98px){
                    font-size: 50px;
                }
                }
            span{
                font-size: 27px;
                line-height: 26px;
                color: #fff;
                @media (max-width: 1199px){
                    font-size: 22px;
                }
                @media (max-width: 991px){
                    font-size: 20px;
                }
            } 
        }
        }
}
 
            @media (max-width: 991px){
                .first_section.flex_class{
                        .circle_section{
                            justify-content: space-between;
                        } 
                        h2{
                            font-size: 24px;
                        }
                            p{
                            @media (max-width: 767px){
                            margin-bottom: 0;
                        }
                    }
                } 
            }
        .second_section {
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto;
            background-image: linear-gradient(90deg, rgb(32, 164, 221) 0%, rgb(30, 163, 182) 24%, rgb(37, 160, 123) 100%);

            h3 {
                font-size: 38px;
                font-family: var(--inte-BD);
                color: #fff;
                margin: 80px 0 40px;

                @media (max-width: 1280px){
                    font-size: 34px;
                    margin: 50px 0 30px;
                }
                @media (max-width: 991px){
                    font-size: 25px;
                }
            }
            @media (max-width: 991px){
                margin: 0;
                padding: 0;
            }
            @media (max-width: 767px){ 
                padding: 0 15px 20px; 
            }
            .main_card_section {
                    display: grid;
                    grid-template-columns: repeat(3 , 1fr);
                    gap: 40px;
                    max-width: 1100px;
                    margin: 0 auto;
                    padding: 0 15px;
                    @media (max-width: 767px){
                        grid-template-columns: repeat(1 , 1fr);gap: 20px;
                    }
                    @media (max-width: 767px){
                        padding: 0;
                    }
                }
            .container_one {
                max-width: calc(100% - 720px); 
                @media (max-width: 1024px){
                    max-width: calc(100% - 510px);
                } 
                @media (max-width: 991px){
                    max-width: calc(100% - 438px);
                } 
                @media (max-width: 767px){
                    max-width: 100%;
                } 
            }   
            .container_two{
                max-width: 100px;
                @media (max-width: 991px){
                    max-width: inherit;
                } 
                @media (max-width: 767px){
                    display: none;
                    } 
            }
            .container_three {
                max-width: 600px;
                width: 100%;
                @media (max-width: 1280px){
                    max-width: 400px;
                img{
                    width: 100%;
                }
            }
            @media (max-width: 991px){
                max-width: 325px;
            }
            @media (max-width: 767px){
                max-width: 100%;   
                margin-top: 20px;
            }
            .container_three {
                margin-top: 30px;
            }
            }
            .lists {
                margin-top: 20px;
                @media (max-width: 1280px){
                    max-width: 500px;
                    margin: 20px auto 0;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 38px;
                    justify-content: center;
                    display: none;
                    li{
                        font-size: 27px; 
                        text-align: center;
                    }
                } 
                p {
                    text-align: center;
                    font-size: 27px; 
                    font-family: var(--common-font); 
                    color: #000000;
                    @media (max-width: 1280px){
                        font-size: 22px;
                    }
                }
            }
        }
        .titles{
            text-align: center;
            margin-top: 30px;
                    h2{
                    font-size: 45px;  
                    margin-bottom: 15px;
                
                    @media (max-width: 1280px){
                        font-size: 36px;
                    }
                    @media (max-width: 1024px){
                        font-size: 28px;
                        line-height: normal;
                    } 
                    @media (max-width: 991px){
                        font-size: 24px; 
                    } 
                }
                h3{
                    font-size: 38px;
                    margin-top: 0;margin-bottom: 30px;
                    color: #000;
                    @media (max-width: 1280px){
                        font-size: 26px;
                    }
                    @media (max-width: 1024px){
                        font-size: 24px;
                        margin-bottom: 20px;
                        br{
                            display: none;
                        }
                    }
                } 
        }
        .texts{
                p{
                font-size: 24px;
                line-height: normal;
                text-align: center;
                margin-bottom: 15px;
                color: #000000;
                    @media (max-width: 1280px){
                            font-size: 22px;
                        }
            }
        }
    
    .price_button{
        text-align: center;
        p{
            text-align: center;
            line-height: normal;
            font-size: 87px;
            color: #2c3e50; 
            font-family: var(--common-fontmd); 
            @media (max-width: 1280px){
                font-size: 79px;
            }
            @media (max-width: 1024px){
            font-size: 65px;
            }
            @media (max-width: 767px){
            font-size: 55px;
            }
        }
        button {
            min-width: 214px; 
            border-radius: 23px;
            background-color: var(--green);
            font-size: 24px;
            color: #ffffff;
            font-weight: bold;
            font-family: var(--exo);
            text-align: center;
            border: none;
            @media (max-width: 1024px){
            font-size: 22px;
            }
            @media (max-width: 767px){
            font-size: 20px;
            }
        }
    }

.listing {
    margin: 60px 0;
    @media (max-width: 991px){
        margin: 30px 0;  
    }
    p {
    font-size: 18px;
    color: #000000;
    font-family: var(--common-font);
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 767px){
    font-size: 16px;
    line-height: normal;
    }
}
}
.secotion_three {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    .include_list{
        margin-top: 80px;
        @media (max-width: 991px){
            margin-top: 40px;
        }
        @media (max-width: 767px){
            margin-top: 20px;
        }
        h3 {
            font-size: 43px;
            line-height: 49px;
            color: var(--green); 
            font-family: var(--inte);
            @media (max-width: 1280px){
                font-size: 36px;
            }
            @media (max-width: 991px){
              font-size: 28px;
            }
            @media (max-width: 767px){
              font-size: 26px;
              line-height: normal;
            }
        }
        p {
            font-size: 28px;
            line-height: normal;
            font-family: var(--common-font); 
            @media (max-width: 1280px){
                font-size: 26px;
            }
            @media (max-width: 991px){
                font-size: 22px;
        } 
        }
    }
}  


/* 3rd section */
 
.first_container{
    background-image: linear-gradient(90deg, #e40685 0%, #bd1b86 21%, #632e8a 100%);
    padding: 20px 0;
    text-align: center;
    color: #ffffff;
     h3 {
        font-size: 28px;  
        font-family: var(--inte); 
        margin: 0 0 20px;
        @media (max-width: 767px){
            font-size: 26px;  
        }
    }
    p{
        font-size: 25px;
        font-family: var(--common-font);
        display: inline-flex;
        button{
            border-radius: 30px;
            background-color: var(--green);
            font-size: 24px;
            color: #ffffff;
            font-weight: bold;
            font-family: var(--exo);
            padding: 10px 20px;
            margin: 0 10px;
            @media (max-width: 767px){
            font-size: 22px;  
            }
        }
    } 
}
.home_third_section{
    /* margin-top: 60px; */
    position: relative;
    z-index: 1;
    background: #ffffff63; 
    .second_container {
        padding: 60px 0;
        text-align: center; 
        .text_section {
            max-width: 1170px;
            margin: 0 auto;
            padding: 0 15px;
            p {
                font-size: 24px;
                color: #000000;
                font-family: var(--common-font);
                text-align: center;
                margin-bottom: 20px;
                @media (max-width: 1280px){
                    font-size: 22px;
                } 
               
            }
        }
        @media (max-width: 767px){
            padding: 45px 0;  
        }
    } 
    .title_Section{ 
        max-width: 1140px;
        margin: 0 auto; 
            h2 { 
                font-size: 43px;
                line-height: 49px;
                color: var(--green); 
                font-family: var(--inte-BD);
                text-align: center;
                    @media (max-width: 1280px){
                        font-size: 36px;
                    }
                    @media (max-width: 767px){
                        font-size: 26px;line-height: normal
                    }
                }
                h3 {
                    font-size: 38px;
                    margin-bottom: 30px;
                    font-family: var(--common-font);
                    color: #000;
                    @media (max-width: 1280px){
                    font-size: 28px;

                } 
                @media (max-width: 767px){
                        font-size: 22px;
                        line-height: normal;
                        margin-bottom: 10px;
                    }
            }

    }
    .backend_titles{
        h3 {
            font-size: 38px; margin-bottom: 30px;
            font-family: var(--common-font);
            color: #000;
            @media (max-width: 1280px){
                font-size: 28px;
            }
            @media (max-width: 767px){
                        font-size: 22px;
                        line-height: normal;
                    }
            span {
                width: 100%;
                display: block;
                
            }
        }
    }
.dna_reports {
    max-width: 849px;
    border-radius: 28px;
    background-color: var(--green);
    margin: 0 auto; 
    padding: 40px;
    @media (max-width: 991px){
        padding: 20px;
        }
        @media (max-width: 767px){
            margin-bottom: 30px;
            float: left;
            width: 100%;
        }
      
    ol {
        position: relative;
        max-width: fit-content;
        margin: 0 auto;
        text-align: left;
        li {
            color: #fff;
            font-size: 25px;
            font-family: var(--common-font);
          
        @media (max-width: 991px){ 
                font-size: 24px;
        }
        @media (max-width: 767px){ 
                font-size: 20px;
        }
        }
    }
    h3 { 
        text-transform: uppercase;
        font-size: 43px;
        line-height: 49px;
        color: #fff; 
        font-family: var(--inte-BD);
        text-align: center;
        margin: 0 0 30px;
        @media (max-width: 1280px){ 
          font-size: 36px;
          line-height: normal;
        }
        @media (max-width: 1199.98px){ 
          font-size: 30px; 
        }
        @media (max-width: 991px){ 
          font-size: 26px; 
        }
        @media (max-width: 767px){
            font-size: 20px;
            margin: 0 0 15px;
        }
        span {
            text-transform: lowercase;
            font-size: 43px;
            @media (max-width: 991px){
              font-size: 26px;
            }
            @media (max-width: 767px){
            font-size: 22px;
        }
        }
    }
}
}
.icons_content {
    margin-top: 65px;
    max-width: 1070px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;  
    clear: both; 
    @media (max-width: 767px){
        margin-top: 45px;
    }
    .iconContainer{ 
    flex: auto;
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    gap: 15px;
    @media (max-width: 1024px) { 
        margin-bottom: 0;
    }
    @media (max-width: 767px) {
        margin-top: 0; 
        gap: 0; 
    } 
        span { 
        width: 125px;
        height: 125px;
        border-radius: 17px;
        background-color: var(--green);
        display: flex;
        align-items: center;
        justify-content: center;
            @media (max-width: 991px){ 
                aspect-ratio: 1/1;
                img{
                    max-width: 60px;
                }
            }
            @media (max-width: 767px) {
                margin: 0 auto;
            }
        } 
            p {
                width: 100%;
                text-align: center;
                max-width: 140px;
                line-height: normal;
                margin-bottom: 0;
                font-size: 18px;
                color: #000000;
                font-family: var(--common-font);
                text-align: center;
                @media (max-width: 767px){
                    font-size: 16px;
                    margin: 10px 0 20px;
                }
            }
                &:last-child p {
                    max-width: 100%;
                    @media (max-width: 767px){ 
                        word-break: break-all;
                        margin: 10px auto;
                    }
                } 
    }
    & > div {
        display: grid;
        grid-template-columns: repeat(5,1fr);
        justify-content: center;
        gap: 20px;
        margin-bottom: 30px;
        @media (max-width: 1280px) { 
            gap: 18px;
           :last-child{
            // margin-bottom: 0px; 

            @media (max-width: 767px) {
                margin-bottom: 10px; 
            }
           }
        }
        @media (max-width: 767px) {
            gap: 0;    
            margin-bottom: 0;
            grid-template-columns: repeat(2,1fr);
        }
        img{
            max-width: 100px;
        }
    }   
}
@media (max-width: 767px){
.first_six { 
    .iconContainer{  
        p{
            max-width: 100%;
        }
    }
}
} 

.image_section {
    position: relative;
    span {
    width: 65px;
    height: 65px;
    border-radius: 39px;
    background-color: #ffffff;
    display: grid;
    place-items: center;
    box-shadow: 2px 3px 8px rgb(0 0 0 / 35%);
    line-height: normal;
    position: absolute;
    left: -20px;
    top: -20px;
    i{
        font-style: normal;   font-family: var(--inte-BD);
    font-size: 35px;    margin-top: -10px;
        @media (max-width: 991px){ 
            font-size: 26px;
            margin-top: -8px; 
        }
    }
    @media (max-width: 991px){
        width: 55px;
        height: 55px;
    }
}
}
.container_of_section {
    h4 {
        font-size: 38px;
        color: #fff;
        margin-bottom: 25px;
        font-family: var(--common-fontmd);
    @media (max-width: 1199px){
        margin-top: 0;
        font-size: 34px;
    }
    @media (max-width: 991px){ 
        font-size: 28px;
    }
    }
p {
    font-size: 24px;
    color: #fff;
    max-width: 285px;
    margin: 20px auto 60px;
    @media (max-width: 1280px){
        font-size: 20px;
        line-height: normal; 
    }
    @media (max-width: 767px){
        margin: 20px auto 20px;
    }
}
}
.fourth_first_container {
    max-width: 1070px;
    width: 100%;
    color: #fff;
    position: relative;
    z-index: 1;
        h2 {
            text-align: center;
            font-size: 55px;
            color:#fff;
            @media (max-width: 1280px){
                font-size: 45px;
            }
            @media (max-width: 767px){
                font-size: 30px;
            }
        }
        h3 {
            font-size: 40px;
            line-height: 49px;  
            color: #000;
            font-family: var(--inte-BD);
            text-align: center;
            /* margin-bottom: 30px; */
            margin: 0;
            color:#fff;
            @media (max-width: 1600px){
                font-size: 40px;
            }
            @media (max-width: 1199.98px){
                font-size: 36px;
            }
            @media (max-width: 991px){
                font-size: 28px;
                line-height: normal;
            }
            @media (max-width: 767px){
                font-size: 22px; 
                margin-bottom: 10px;
            }
            & p{
                display: block;
                margin-bottom: 0;
                font-size: 40px;
                line-height: 49px; 
                font-family: var(--inte-BD); 
                @media (max-width: 1600px){
                font-size: 40px;
            }
                @media (max-width: 1280px){
                font-size: 36px;
            }
            @media (max-width: 991px){
                font-size: 28px;
                line-height: normal;
            }
            @media (max-width: 767px){
                font-size: 22px;  
            }
                span {
                    /* text-decoration: underline; */
                    font-family: var(--inte);
                    background: linear-gradient(44deg,#ff8632 0%,#dd7f3f 21%,#ff8632 100%);
                    background-size: 300%;  
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    /* border-bottom: 4px solid #fff;  */
                    animation: animated_text 3s ease-in-out infinite; 
                    font-family: var(--inte-BD);
                }
                span.color-gradient {
                    background: linear-gradient(-45deg, #6355a4, #6355a4, #e89a3e, #e89a3e);  
                    animation: animated_text 10s ease-in-out infinite; 
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-size: 300%;  
                    border-bottom: 0;
                }

                @keyframes animated_text {
                    0% { background-position: 0px 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0px 50%; }
                }
            }
        }
      & >  p{
        font-size: 24px; 
        font-family: var(--common-font);
        text-align: center;
        margin-bottom: 40px; 
        :nth-child(3) {
                    background: linear-gradient(-45deg,#6355a4,#6355a4,#e89a3e,#e89a3e);
                    padding: 10px;
                    max-width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                    font-family: var(--common-fontmd);
                    }
        @media (max-width: 1280px){
            margin-bottom: 25px;
            font-size: 22px;
        }
        @media (max-width: 767px){
            margin-bottom: 15px;
            font-size: 20px;
        }   
        }
        @media (max-width: 767px){
            padding-left: 15px;
            padding-right: 15px;
        }
}

.home_fourth_section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    background: linear-gradient(90deg, #00a0e1 0%, #009db0 24%, #00997c 100%);
    /* background-image: linear-gradient(90deg, #20a4dd 0%, #1ea3b6 24%, #25a07b 100%); */
    padding: 80px 0 0;
    max-width: 100%;
    width: 100%;
    position: relative; 
    z-index: 0;
    gap: 40px;

    @media (max-width: 1280px){
        padding: 50px 0 0;
    }
    @media (max-width: 991px){
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 0px 0 0px;
        margin-top: 0px;
    }
    @media (max-width: 767px){ 
        padding: 0px 0 0px; 
    }

 
    
  .img { 
    position: absolute;
    top: 50%;
    left: calc(100% - 650px);
    z-index: 0;
    width: 100%;
    transform: translateY(-50%);
    @media (max-width: 1600px){
        left: calc(100% - 470px);
            img{
            max-width: 770px;
            @media (max-width: 1600px){
                max-width:  670px;
            }
            
            @media (max-width: 1280px){
                max-width: 570px
            } 
            @media (max-width: 991px){
            max-width: 100%;
            }
            } 
    } 
    @media (max-width: 1280px){
         left: calc(100% - 400px);
         top: 48%;
        } 
        
    @media (max-width: 991px){
        left: 50%;
        transform: translate(-50% , 0%);
        top: -165px;
        right: auto;
        width: auto;
    } 
    @media (max-width: 767px){ 
        top: -155px;
        max-width: 360px;
        width: 100%;
    }
    }
    .capsule {
        display: flex;
        gap: 70px;
        @media (max-width: 991px){ 
             padding: 0 20px;       
             gap: 50px;
             @media (max-width: 767px){
                justify-content: center;
                flex-wrap: wrap;
                text-align: center;
             }
        }
        p {
            font-size: 22px;
            color: #fff;
            font-family: var(--common-font);
            margin: 0 0 14px;
            @media (max-width: 767px){
                margin: 0 0 10px;
                font-size: 20px; 
            }
        }
        img {
            max-width: 250px;
        }
        a {
            padding: 10px 30px;
            border-radius: 30px;
            background: linear-gradient(90deg,#e40685 0%,#bd1b86 21%,#632e8a 100%);
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            &:hover {
                text-decoration: unset;
            }
        }
        /* position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);  z-index: 1;
        @media (max-width: 1199.98px){
            right: 20px;
        }
        @media (max-width: 1280px){
            top: 47%;
        }
        @media (max-width: 1440px){
            img {
                max-width: 220px;
                @media (max-width: 1280px){
                    max-width: 180px;
                }

                @media (max-width: 991px){
                    max-width: 160px;
                }
            }
            
         }
         @media (max-width: 1024px){
            top: 46%;
        }
        @media (max-width: 991px){
            top: -65px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        } */
    } 
    .personalized_icons{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; 
        max-width: 800px;
        margin: 0 auto;
            .iconContainer {
            max-width: calc(100%/5 - 20px);
            flex: auto;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;
            gap: 15px;
                @media (max-width: 767px){
                 max-width: calc(100%/2 - 20px);
                }
            p {
            width: 100%; 
            line-height: normal;
            margin-bottom: 0;
            font-size: 20px;
            color: #000;
            font-family: var(--common-font);
            text-align: center;
            @media (max-width: 767px){
                margin-bottom: 15px;
            }
            }
            span {
                display: block;
                width: 125px;
                height: 125px;
                border-radius: 17px;
                background-color: var(--green);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        @media (max-width: 767px){
            flex-wrap: wrap;
        }
    }
  
    .inner-boxes-difference {
        background-image: linear-gradient(90deg,#e0e4e6 0%,#c0cbd1 100%);
        width: 100%;
        margin-top: -41px;
        padding: 20px 0;
    }
    .video-inner-boxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        background-image: linear-gradient(90deg, #e0e4e6 0%, #c0cbd1 100%);
        max-width: 1040px;
        margin: 0 auto;
        &>div {
        min-height: 290px;
        padding: 86px 0;
    }
    .boxes-video1 {
        height: auto;
        border-radius: 26px;
        background-image: linear-gradient(90deg, #e00080 0%, #ba1681 21%, #642d85 100%);
        width: 328px;
        display: flex;
        align-items: center;
        justify-content: center;
        position:relative;
        flex-wrap: wrap;
        ::after{
            position: absolute;
            clip-path: polygon(50% 0%, 35% 100%, 100% 100%);
            content: "";
            bottom: -22px;
            right: 38px;
            background: linear-gradient(90deg, #e00080 0%, #ba1681 21%, #642d85 100%);
            width: 40px;
            height: 40px;
            transform: rotate(83deg);
        }
        h6{
            font-size: 31px;
            color: #ffffff;
            text-align: center;
            max-width: 250px;
            margin: 0 auto;
            letter-spacing: 0.5px;

        }
            }
            .boxes-video2 {
                background-image: linear-gradient(90deg, #ffc500 0%, #e45323 100%);
                width: 328px;
                display: flex;
                align-items: center;
                justify-content: center;
                position:relative;
                height: auto;
                border-radius: 26px;
                flex-wrap: wrap;
                ::after{
                    background: linear-gradient(90deg, #ffc500 0%, #e45323 100%);
                    position: absolute;
                    clip-path: polygon(50% 0%, 35% 100%, 100% 100%);
                    content: "";
                    bottom: -22px;
                    right: 38px;
                    width: 40px;
                    height: 40px;
                    transform: rotate(83deg);
                }
                h6{
            font-size: 31px;
            color: #ffffff;
            text-align: center;
            max-width: 250px;
            margin: 0 auto;
            letter-spacing: 0.5px;

        }
            }
            .boxes-video3 {
                background-image: linear-gradient(90deg, #00a2e3 0%, #009fb2 24%, #009b7c 100%);
                width: 328px;
                display: flex;
                align-items: center;
                justify-content: center;
                position:relative;
                height: auto;
                border-radius: 26px;
                flex-wrap: wrap;
                ::after{
                    background: linear-gradient(90deg, #00a2e3 0%, #009fb2 24%, #009b7c 100%);
                    position: absolute;
                    clip-path: polygon(50% 0%, 35% 100%, 100% 100%);
                    content: "";
                    bottom: -22px;
                    right: 38px;
                    width: 40px;
                    height: 40px;
                    transform: rotate(83deg);
                }
                h6 {
                    font-size: 31px;
                    color: #ffffff;
                    text-align: center;
                    max-width: 250px;
                    margin: 0 auto;
                    letter-spacing: 0.5px;
                }
                .star-review {
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;
                }
        }
    }
    .as_seen_section {
        width: 100%;
        background: #fff;
        text-align: center;
        padding: 30px 0;
        position: relative;
        z-index: 10;

        @media only screen and (max-width:991px) {
            margin:  0 0 40px; 
        }
    
        .cstm_container {
            max-width: 974px;
            margin: 0 auto;
            padding: 0 10px;
    
            @media only screen and (max-width: 1199px) {
                max-width: 950px;
            }
    
            .new_titles {
                margin-bottom: 30px;
                
                @media only screen and (max-width: 991px) {
                    margin-bottom: 10px;
                }
                h2 {
                    margin: 0;
                    font-size: 30px;
    
                    @media only screen and (max-width: 767px) {
                        font-size: 25px;
                    }
                }
            }
        }
    
        .logo-cstm-wrap {
            ul {
                display: flex;
                align-items: center;
                margin: 0 -10px;
                list-style: none;
    
                @media only screen and (max-width: 767px) {
                    flex-wrap:wrap;
                }
    
                li {
                    width: calc(100%/6);
                    padding: 0 10px;
    
                    @media only screen and (max-width: 767px) {
                        width: calc(100%/3);
                        padding: 10px;
                    }
    
                    @media only screen and (max-width: 575px) {
                        width: calc(100%/2);
                    }
    
                    &:nth-child(2), &:nth-child(5) {
                        figure {
                            height: 50px;
    
                            @media only screen and (max-width: 1199px) {
                                height: 40px;
                            }
    
                            @media only screen and (max-width: 767px) {
                                height: 30px;
                            }
                        }
                    }
    
                    figure {
                        width: 100%;
                        height: 80px;
                        margin: 0;
    
                        @media only screen and (max-width: 1199px) {
                            height: 60px;
                        }
    
                        @media only screen and (max-width: 767px) {
                            height: 50px;
                            max-width: 100px;
                            margin: 0 auto;
                        }
    
                        @media only screen and (max-width: 575px) {
                            height: 50px;
                        }
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}
    a.get-yours-third {
        padding: 10px 30px;
        border-radius: 30px;
        background: linear-gradient(90deg,#e40685 0%,#bd1b86 21%,#632e8a 100%);
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }
    .home_fourth_section.seen-on{
    gap: 0px;
    .fourth_first_container, .fourth_second_container, .fourth_third_container {
	margin-bottom: 40px;
}
}

/* advance-privacy-section */
.home_fifth_section{
    position: relative;
    z-index: 1;
    padding-top: 90px;
    @media (max-width: 1280px){
        padding-top: 60px;
    }
    @media (max-width: 767px){
        padding-top: 50px;
    }
    h2 {
        color: #000;
        text-align: center;
        margin-bottom: 30px;
    }
    .review_section { 
        .rating_section_content {
            text-align: center;
            justify-content: center;
            gap:40px;
            @media (max-width:767px){
                gap:15px;
                flex-wrap:wrap;
            }

            .contain-bx {
                p {
                    font-size: 30px !important;

                    @media (max-width:767px){
                        font-size: 24px !important;
                    }
                }
            }
        }
    /* padding: 50px 0;
    max-width: 1070px;
    margin: 0 auto;   */
    >div { 
        margin-bottom: 40px; 
    } 
    }
    .video_secFive { 
        box-shadow: 0 0 12px rgb(0 0 0 / 50%);  
        max-width: 1023px;
        /* margin-top: 200px; */
        height: 434px;
        margin: 0 auto 60px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        @media (max-width: 991px){
            height: 430px;
            max-width: max-content;
        }
        @media (max-width: 767px){
            height: auto;
            max-width: calc(100% - 40px);
        }
        a {
            display: block;
        }
        iframe{ 
        position: absolute;
        top: 50%;
        transform: translate(-50% , -50%);
        left: 50%;
        /* width: 100vw; */
        height: 100%;   
        @media (max-width: 767px){
            width: 100%;
            height: 100%;
        }
        }
        & > img {
            position:${props => props?.clickOn ? 'unset' : 'relative'};
            z-index: 9;
            width: 100%;
        }
    } 
    .contain-bx {
    max-width: 328px;
    height: 328px;
    width: 100%;
    margin: 0 auto; 
    padding: 30px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: 1024px){
        max-width: 100%;
        height: 288px;
    }

    @media (max-width: 991px){ 
        height: 225px;
        max-width: calc(100% - 10px);
    }
            &:before {
            content: "";
            position: absolute;
            top: 100%;
            right: 70px;
            width: 0;
            height: 0;
            border-top: 42px solid #812888;
            border-right: 42px solid transparent;
        }
    p {
    font-size: 24px;
    color: #ffffff;
    font-family: var(--common-font);
    text-align: center;
    margin-bottom: 20px; 
    line-height: 37px;
        @media (max-width: 1280px){
            font-size: 22px;line-height: normal;
        }     
}
span.MuiRating-root {
    color: #fff;
    font-size: 20px;
    display: flex;
    gap: 5px;
}

}
.contain-bx.prplbx {
    border-radius: 26px;
    background-image: linear-gradient(90deg, #e40685 0%, #bd1b86 21%, #632e8a 100%);
 
}
.contain-bx.orange-bx {
    border-radius: 26px;
    background-image: linear-gradient(90deg, #fec90b 0%, #e94f24 100%);
    &:before { 
    border-top: 42px solid #ed6a1e; 
}
}
.contain-bx.gree-bx {
    border-radius: 26px;
    background-image: linear-gradient(90deg, #20a4dd 0%, #1ea3b6 24%, #25a07b 100%);
    &:before { 
    border-top: 42px solid #22a190; 
}
}
.text_section{ 
    max-width: 820px;
    margin: 0 auto;
    .d-flex {
        gap: 30px; 
        @media (max-width: 1280px){
            align-items: center;
           @media (max-width: 767px){
            flex-wrap: wrap;
            gap: 20px; 
           }
            > div{
                p:last-child{
                    margin-bottom: 0;
                    @media (max-width: 991px){
                        margin-bottom: 20px;
                    }
                    @media (max-width: 767px){
                        margin-bottom: 0px;
                    } 
                }
            } 
        }
    }
    h2{
        margin-bottom: 30px;
        font-size: 60px;
        line-height: 70px; 
        @media (max-width: 1280px){
            font-size: 45px; 
            line-height: normal;
        }
        @media (max-width: 767px){
            font-size: 26px;
            line-height: normal;
            text-align: center;
            margin-bottom: 20px; 
        }
    }
    p{
        font-size: 25px;
        color: #000000;
        font-family: var(--common-font); 
        margin-bottom: 30px;
        @media (max-width: 1280px){
            font-size: 22px;
        }   
        @media (max-width: 767px){
        font-size: 20px;  
        margin-bottom: 15px;
        line-height: normal;
        text-align: center;
    }
    }
}



.container{
    max-width: 1440px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
}
    .free-ship-cap{ 
        color: rgb(255, 255, 255);
        font-size: 21px;
        font-style: italic; 
        text-align: center;
        margin: 5px 0 0;
    }


.first_section.flex_class .image_section {
    padding: 70px 0 0;
}
.calender-data img {
    width: 07%;
    max-width: 30px;
    position: relative;
    bottom: 52px;
    height: 100%;
    cursor: pointer;
}

.calender-data {
    position: relative;
    margin-top: -19px;
    z-index: 10;
}
.calender-data img{
    width: 07%;
    max-width: 50px;
    height: 100%;
    position: relative;
    cursor: pointer;
    margin-top: 22px;
}

.calendar-text {
    position: absolute;
    -ms-transform: translateX(-50%);
    transform: translateX(-110%);
    border-radius: 3px;
    left: 50%;
    -ms-transform: translateX(-50%);
    background-color: #f8f8f8;
    color: #000;
    font-size: 12px;
    padding: 5px;
    top: -46px;
    opacity: 0;
    visibility: hidden;
}
.calender-data img:hover + span.calendar-text {
    opacity: 1;
    visibility: visible;
}


`;

export default HomeComponent;